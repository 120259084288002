<template>
    <div class="auth-area frmforgetpasswd" id="forgot-password" v-cloak>
        <div class="login-area">
            <form @submit.prevent="nextStep">
                <suspended-alert v-if="errorsForm.accountCancelled" novalidate>
                    <template slot="message">Your account has been suspended.</template>
                </suspended-alert>

                <div class="login-form">
                    <auth-form-progress v-if="request_send"></auth-form-progress>

                    <auth-form-header :login="true">
                        <template v-slot:header-label>{{ step === 1 ? "Reset Your Password" : "Done and Done!" }}</template> <!-- NEMESIS CHANGE <template v-slot:header-label>{{ step === 1 ? "Reset Password" : "Done and Done!" }}</template>-->

                        <template v-if="step === 1" v-slot:sub-header-label-resetpasswd><!--NEMESIS CHANGE <template v-if="step === 1" v-slot:sub-header-label> -->
                            Fear not. We’ll email you instructions to reset your password.
                        </template>
                        <template v-else v-slot:sub-header-label>
                            <div class="email-sent-done">
                                <img
                                    alt="Email Sent"
                                    class="img-responsive"
                                    src="../../assets/img/auth/sent-email-done.svg"
                                />
                                <div class="block">
                                    <div class="success-title">Success!</div>
                                    <div
                                        class="success-message"
                                    >We’ve sent an email to {{ censorEmail(credentials.email) }} with password reset instructions.
                                    </div>
                                </div>
                            </div>
                        </template>
                    </auth-form-header>

                    <div :class="{'password-body': step === 2}" class="auth-form-body">
                        <auth-email-field
                            :credentials="credentials"
                            :errors-form="errorsForm"
                            v-if="step === 1"
                        ></auth-email-field>

                        <div class="form-group" v-else>
                            If the email doesn’t show up soon, check your spam folder. We sent it from support@bigcommand.com.
                        </div>

                        <div class="row login-body-footer"><!--NEMESIS CHANGE <div class="form-group row"> -->
                            <div class="col-xs-6 create-account-link" v-if="step === 1">
                                <router-link :disabled="request_send" to="/login/1">
                                    Return to Sign in
                                </router-link>
                            </div>

                            <div :class="{'col-xs-6': step === 1, 'col-xs-12': step === 2}" class="text-right">
                                <button :disabled="request_send" @click="nextStep" class="btn btn-primary" type="submit">
                                    {{ step === 1 ? 'Next' : 'Return to Login' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <support-link></support-link>
            </form>
        </div>

        <auth-footer></auth-footer>
    </div>
</template>

<script>
    import AuthFooter from "./Items/AuthFooter";
    import SupportLink from "./Items/SupportLink";
    import AuthFormProgress from "./Items/AuthFormProgress";
    import AuthFormHeader from "./Items/AuthFormHeader";
    import SuspendedAlert from "./Items/SuspendedAlert";
    import AuthEmailField from "./Items/AuthEmailField";

    export default {
        name: "ForgotPassword",
        components: {
            AuthEmailField,
            SuspendedAlert,
            AuthFormHeader,
            AuthFormProgress,
            AuthFooter,
            SupportLink
        },
        data: () => ({
            credentials: {
                email: ""
            },
            step: 1,
            request_send: true,
            errorsForm: {
                email: "",
                invalidEmail: "Invalid Email Address",
                emailNotFound: false,
                emailInvalid: false,
                accountCancelled: false
            }
        }),
        mounted() {
            this.request_send = false;
        },
        methods: {
            nextStep() {
                this.clearValidate();

                if (this.step === 1) {
                    if (this.validateEmail()) {
                        this.request_send = true;

                        this.$http
                        .post(`/send-reset-password-link`, {
                            email: this.credentials.email
                        })
                        .then(r => {
                            this.request_send = false;
                            if (r.data.result === "success") {
                                window.BCookie.set(
                                    "BC-Auth-Email",
                                    this.credentials.email,
                                    259200
                                );

                                this.step = 2;
                            } else if (r.data.result === "cancelled") {
                                this.errorsForm.accountCancelled = true;
                            } else {
                                this.errorsForm.emailNotFound = true;
                                this.errorsForm.email = r.data.message;
                            }
                        })
                        .catch(e => {
                            this.request_send = false;
                            this.errorsForm.emailInvalid = true;
                        });
                    } else {
                        this.errorsForm.emailInvalid = true;
                    }
                } else {
                    this.$router.push("/login/1");
                }
            },

            clearValidate() {
                this.errorsForm.accountCancelled = false;
                this.errorsForm.emailInvalid = false;
                this.errorsForm.emailNotFound = false;
            },

            /**
             * Email validation check.
             * @returns {boolean}
             */
            validateEmail() {
                let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(this.credentials.email).toLowerCase());
            },

            censorWord(str, flag) {
                return `${str[0]}${"*".repeat(flag ? 7 : 5)}${str.slice(
                    flag ? -1 : -3
                )}`;
            },
            censorEmail(email) {
                let arr = email.split("@");

                return `${this.censorWord(arr[0], true)}@${this.censorWord(
                    arr[1],
                    false
                )}`;
            }
        }
    };
</script>

<style lang="less" src="../../assets/less/custom/pages.less"></style>
<style lang="less" scoped src="../../assets/less/custom/auth.less"></style>
